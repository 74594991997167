import React from "react"
import { StoryGroup } from "components"
import { flatStoryGroup } from "utils/array-util"

const eBooks = [
  {
    title: "How to control re-renders on React Class Components",
    url:
      "https://learnreactui.dev/contents/how-to-control-re-renders-on-react-class-components",
    img:
      "https://storage.googleapis.com/argon-app-web-prod/assets%2Fc408e158-7145-4d91-a1c0-58cd1632d0d4%2Fcovers%2FPCC_Cover2.png",
  },
  {
    title: "How to control re-renders on React Function Components",
    url:
      "https://learnreactui.dev/contents/how-to-control-re-renders-on-react-func-components",
    img:
      "https://storage.googleapis.com/argon-app-web-prod/assets%2Fdf3714e7-9724-4d24-ad72-03f806718df9%2Fcovers%2FCover1.png",
  },
  {
    title: "React Tab Visibility Optimization",
    url: "https://learnreactui.dev/contents/react-tab-visibility-optimization",
    img:
      "https://storage.googleapis.com/argon-app-web-prod/assets%2Ff7f64828-1a4f-4c3b-bcb3-7e665d3a7762%2Fcovers%2FCover.png",
  },
]

export const LinkMap = {
  shouldComponentUpdate: "76e2495fdc3b",
  PureComponent: "76e2495fdc3b",
  "React.memo": "76e2495fdc3b",
  useCallback: "e9957e672c3e",
  "document.hidden": "80e8623494db",
}

const storiesHooksPerf = [
  {
    title: "React Performance-1 (re-render)",
    postId: "76e2495fdc3b",
  },
  {
    title: "React Performance-2 (useCallback)",
    postId: "e9957e672c3e",
  },
  {
    title: "React Performance-3 (useMemo)",
    postId: "6f52578e3fa0",
  },
]

const storiesWebAPIPerformance = [
  {
    title: "Tab Visibility Optimization",
    postId: "80e8623494db",
  },
]

const zustandPerformance = [
  {
    title: "Zustand, DevTool Integration",
    postId: "4262c09ce22f",
  },
  {
    title: "Zustand Re-Render Use Case",
    postId: "d97a58188625",
  },
  {
    title: "Zustand Re-Render Use Case2",
    postId: "853f01ede4e8",
  },
]

const storiesGroup = [
  {
    title: "Hooks Performance",
    storyPart: storiesHooksPerf,
  },
  {
    title: "Web API Performance",
    storyPart: storiesWebAPIPerformance,
  },
  {
    title: "Zustand Performance",
    storyPart: zustandPerformance,
  },
]

export const context = {
  stories: flatStoryGroup(storiesGroup),
  linkMap: LinkMap,
  title: "React Performance",
  path: "react-performance",
}

const ReactPerformancePage = () => {
  return (
    <StoryGroup
      title={context.title}
      complex={true}
      stories={storiesGroup}
      linkMap={context.linkMap}
      eBooks={eBooks}
    />
  )
}

export default ReactPerformancePage
